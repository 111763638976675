import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Director</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className=''
                // "col-md-4 col-sm-6 team"
                >
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." width={'50%'}/>
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
          
        </div>
 
      </div>
      <div style={{width:'45%', margin:'auto'}} >
      <p style={{}} > Full-Stack developer with a passion for crafting immersive digital experiences. With a robust skill set, we stand at the forefront of innovation in web and mobile app development. </p>
      </div>
    </div>
  );
};
