import React from "react";
import '../components/Project.css'
import port1 from '../port1.jpg'
import adu from '../adu.png'
import xc2 from '../xc2.png'


const Projects = ()=>{


  
    return(
      <div className="portfolio" id="Projects" >
        <div className="portfolio-container">
            <h3>PORTFOLIO</h3>

           <div className="port-box-container">
           <div className="port-box" >
          
          <img src={port1} alt="" width={"100%"}
          height={"70%"} />
          {/* <h5>WEB DESIGN for______ </h5>
          <h5>DATE:</h5>
          <h5>STATUS: </h5> */}
           <button className="visit">
          <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
          </span>
          <a href="https://deyink.github.io/fab-fabrics/" target="blank" ><span className="button-text">Visit Site</span></a>
          </button>
          </div>
            
            <div className="port-box">
                {/* <p style={{textAlign:'center', marginTop:'30%', fontWeight:'600' }}  >Click To Visit</p> */}
                <img src={adu} alt="" width={"100%"} height={"70%"} /> 
                <button className="visit">
                <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
                </span>
                <a href="https://deyink.github.io/currency-converter/" target="blank" >
                <span className="button-text">Visit Site</span> </a>
                </button>
                
            </div>
         
        
       
            
            <div className="port-box">
                {/* <p style={{textAlign:'center', marginTop:'30%', fontWeight:'600' }} >COMING SOON</p> */}
                <img src={xc2} alt="" width={"100%"}  height={"70%"} />
                {/* <h5> FULL-STACK DEV____ </h5>
                <h5>DATE:</h5>
                <h5>STATUS: </h5> */}
                 <button className="" style={{padding:8, marginLeft:'5%', backgroundColor:'#192a56', color:'white', borderRadius:5 }} >
              <a style={{color:'white'}} href="https://expo.dev/accounts/deyink/projects/11Xchange/builds/b7c3ce06-2c4f-445f-9b4e-9fa72655d79a"> <span className="">Get The App</span> </a>
                
                </button>
            </div>
           </div>

        </div>
      </div>
    )
}


export default Projects
